body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
.MuiToolbar-root > div {
	display: flex;
	border: none !important;
	margin-bottom: 0 !important;
}
.MuiButtonBase-root.Mui-disabled {
	color: rgba(0, 0, 0, 0.26) !important;
}

.table-meta tr {
	/* display: flex; */
	width: 100%;
	/* display: inline-table; */
	text-align: left !important;
}

.table-meta tr td a {
	text-align: left !important;
}

.table-meta tr td:nth-last-child(1),
.table-meta tr td:nth-last-child(2),
.table-meta tr td:nth-last-child(3),
.table-meta tr td:nth-last-child(4),
.table-meta tr td:nth-last-child(5),
.table-meta tr td:nth-last-child(6),
.table-meta tr td:nth-last-child(7),
.table-meta tr td:last-child,
.table-meta tr th:nth-last-child(1),
.table-meta tr th:nth-last-child(2),
.table-meta tr th:nth-last-child(3),
.table-meta tr th:nth-last-child(4),
.table-meta tr th:nth-last-child(5),
.table-meta tr th:nth-last-child(6),
.table-meta tr th:nth-last-child(7),
.table-meta tr th:last-child {
	width: 80px !important;
}
.text-left div {
	text-align: left;
}
.text-left a {
	text-align: left;
	display: block;
}
