* {
    font-family: "Montserrat", sans-serif !important;
}

body {
    background-color: whitesmoke;
}

a {
    text-decoration: none;
}

td a {
    text-decoration: underline;
    color: cadetblue;
}

td a:hover {
    color: green;
    background-color: beige;
}

td button {
    color: cadetblue !important;
}

th,
td {
    min-width: 50px;
    padding: 2px !important;
    text-align: center !important;
}

th {
    font-weight: 900 !important;
}

/* td button {
    text-align: left;
} */

th:first-child,
td:first-child {
    text-align: left !important;
}

.exported {
    display: none !important;
}

.csv,
.xlsx,
.txt {
    border: 1px solid darkorange;
    margin-right: 10px !important;
    color: darkorange;
    padding: 2px 10px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.tableexport-caption {
    text-align: right;
}

.csv:hover,
.xlsx:hover,
.txt:hover {
    background-color: rgba(255, 140, 0, 0.3);
}

.export-button {
    margin: 1em !important;
}

footer {
    background-color: darkturquoise;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
}

footer a {
    color: black;
}

.userInput {
    margin-bottom: 50px;
}

.notification-container {
    top: 70px !important;
}

.button-kw {
    color: black !important;
}
